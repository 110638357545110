import React from 'react';
import Layout from "../../components/Layout/ru";
import {Button, Container} from "@mui/material";
import * as styles from "./style.module.css";
import {StaticImage} from "gatsby-plugin-image";
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';



function AuthorPage({location}) {

  return (
    <Layout
      location={location}
      title='Пилип Нєдєлєв'
      description='Інформація та контактні данні Нєдєлєва Пилипа'
    >
      <div className={styles.page}>
        <Container maxWidth="md" style={{height: "100%"}}>
          <div className={styles.contentWrapper}>
            <div className={styles.avatarWrapper}>
              <StaticImage
                className={styles.avatar}
                src="../../../static/img/avatar.png"
                placeholder="blurred"
                alt="моє фото"
              />
            </div>
            <div className={styles.textWrapper}>
              <h1 style={{marginTop:0}}>Пилип Нєдєлєв</h1>
              <h2>Full Stack Developer та Підприємець</h2>
              <p className={styles.paragraph}>
                Я працюю веб розробником вже {new Date().getFullYear() - 2017} років.<br/>
                Моя основна робота це фронтент. Вже декілька років працюю над платформами
                пов'язаними, так чи інакше, з нерухомістю.<br/>
                У вільний від основної роботи час допомагаю молодим розробникам у навчанні та пошуку роботи, а також невеликим стартапам
                у пошуку рішень для їхнього бізнесу.
              </p>
              <p className={styles.paragraph}>
                Якщо ти маєш питання, ти знайшов | знайшла помилку, чи у тебе є пропозиції як зробити сайт кращим,
                ти можеш зв'язатися зі мною
              </p>
              <div style={{width:"290px",display:"flex",flexDirection:"column"}}>
                <a href="https://t.me/NedelevPhilip" target="_blank" style={{width:"100%",margin:"10px 0"}}>
                  <Button variant="contained"
                          style={{backgroundColor:"#0088cc",justifyContent:"start"}}
                          startIcon={<TelegramIcon />}
                          fullWidth
                  >
                    Напиши мені в Telegram
                  </Button>
                </a>
                <a href="https://www.linkedin.com/in/philip-nedelev-9a7264b5/"
                   target="_blank"
                   style={{width:"100%",margin:"10px 0"}}
                >
                  <Button variant="contained"
                          style={{backgroundColor:"#0077b5",justifyContent:"start"}}
                          startIcon={<LinkedInIcon />}
                          fullWidth
                  >
                    Встанови контакт у Linkedin
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}



export default AuthorPage;